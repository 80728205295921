.cardHeader {
  text-align: center;
}

.card {
  margin: auto;
  padding: var(--space-3);
  display: flex;
  flex-direction: column;
  background-color: transparent;
}

.card :global .MuiCardHeader-root,
.card :global .MuiCardContent-root {
  padding: 0;
}

.infoIcon {
  vertical-align: middle;
  width: 1rem;
  height: 1rem;
  margin: 4px;
  color: var(--color-text-secondary);
}
