.chip {
  border-radius: var(--space-2);
  padding-left: 4px;
  padding-right: 4px;
}

.visibilityIcon {
  font-size: 16px !important;
  color: var(--color-border-main) !important;
}

.pendingLoopIcon {
  color: var(--color-info-dark) !important;
}

@media (max-width: 600px) {
  .chip {
    margin: 0 var(--space-2) var(--space-2) var(--space-2);
  }
}
